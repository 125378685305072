@font-face {
  font-family: "Brandon Grotesque";
  font-weight: 400;
  src: url(assets/fonts/Brandon_reg.otf) format("opentype");
}

@font-face {
  font-family: "Brandon Grotesque";
  font-weight: 700;
  src: url(assets/fonts/Brandon_bld.otf) format("opentype");
}
